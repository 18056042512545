import { configureStore } from "@reduxjs/toolkit";
import cardStatsReducer from "../rdx/slices/cardsStatsSlice";

const store = configureStore({
  reducer: {
    cardStats: cardStatsReducer,
  },
});

export default store;
