import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getCardDataThunk = createAsyncThunk(
  "cardStats/getCardData",
  async () => {
      return new Promise((resolve,reject)=>{
        axios.get("https://mitel-dash-demo.thinkallstar.com/api/?customer=WgFzGp6L28CVd58N3GbJzui9DynwQA")
        .then((res)=>{
            resolve(res.data)
        }).catch((err)=>{
            reject(err)
        })
      })
   
  }
);

const cardStatsSlice = createSlice({
  name: "cardStats",
  initialState: {
    card1: null,
    card2: null,
    card3: null,
  },
  extraReducers: {
    [getCardDataThunk.fulfilled]: (state, action) => {
      console.log("Data!", action.payload);
      state.card1 = action.payload.toString()
    },
  },
});

export default cardStatsSlice.reducer;
export const selectCard1 = (state) => state.cardStats.card1;
export const selectCard2 = (state) => state.cardStats.card2;
export const selectCard3 = (state) => state.cardStats.card3;
export const selectAllCardData = (state) => state.cardStats;
