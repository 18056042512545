import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCardDataThunk,
  selectAllCardData,
} from "../../rdx/slices/cardsStatsSlice";
import Card from "../Card";

const TopCards = () => {
  const allCardData = useSelector(selectAllCardData);
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(
      () => {
        dispatch(getCardDataThunk());
      },
      2000
    );

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    // <div className="{{#is navPosition 'sidenav || combo'}}container-fluid{{/is}}{{#is navPosition 'topnav'}}container{{/is}}">
    <div className="container-fluid">
      <div className="row">
        <Card title={"Active Calls"} tag={"Callers"} text={allCardData.card1 ? allCardData.card1 : "..."}></Card>
        <Card title={"From DB"} tag={"..."} text={allCardData.card2 ? allCardData.card2 : "..."}></Card>
        
      </div>
    </div>
  );
};

export default TopCards;
