import React from "react";
import "./styles/theme.scss";
import Home from "./pages/Home";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Sidenav from "./components/Sidenav";
import SignIn from "./pages/SignIn";

function App() {
  return (
    <BrowserRouter>
      <Switch>
          <Route path="/signin" >
            <SignIn></SignIn>
          </Route>
          <Route path="/"> <Sidenav /><Home></Home></Route>
        
      </Switch>
    </BrowserRouter>
  );
}

export default App;
