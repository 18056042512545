import React, { useState } from "react";
import Collapse from "react-css-collapse";
import { useHistory } from "react-router";
import { ReactComponent as BrandLogo } from "../img/miLogo.svg";


export default function Sidenav(props) {
  const [open, setOpen] = useState(false);

  const history = useHistory()

  // const [openCategory, setOpenCategory] = useState('');
  // const [openSubcategory, setOpenSubcategory] = useState('');
  // const [openDropdown, setOpenDropdown] = useState(false);

  // function handleCategoryClick(e, category) {
  //   e.preventDefault();
  //   setOpenSubcategory('');
  //   setOpenCategory(category === openCategory ? '' : category);
  // }

  // function handleSubcategoryClick(e, subcategory) {
  //   e.preventDefault();
  //   setOpenSubcategory(subcategory === openSubcategory ? '' : subcategory);
  // }

  // function handleDropdownClick(e, state) {
  //   e.preventDefault();
  //   setOpenDropdown(state);
  // }

  return (
    <nav
      className="navbar navbar-vertical navbar-vertical-sm fixed-start navbar-expand-md navbar-light"
      id="sidebarSmall"
      {...props}
    >
      <div className="container-fluid">
        {/* Navbar toggler */}
        <button
          className="navbar-toggler"
          type="button"
          aria-controls="sidebarCollapse"
          aria-expanded={!!open}
          aria-label="Toggle navigation"
          onClick={() => setOpen(!open)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Navbar brand */}

          <a className="navbar-brand" onClick={()=>history.push("/signin")}>
            <BrandLogo className="navbar-brand-img mx-auto"></BrandLogo>
          </a>
    

        {/* 
    <!-- User (xs) --> */}
        <div className="navbar-user d-md-none">
        
        </div>

        {/* Navbar collapse */}
        <Collapse
          className="navbar-collapse"
          id="sidebarCollapse"
          isOpen={open}
        >
          {/* Navbar nav */}

          <ul className="navbar-nav">
            <li className="nav-item dropend">
              <a
                className="nav-link"
                id="sidebarSmallDashboards"
                title="Dashboards"
                onClick={()=>alert("Do something here...")}
              >
                
                <i className="fe fe-home"></i>{" "}
                <span className="d-md-none">Dashboards</span>
              </a>
            </li>

            <li
              className="nav-item"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-template='<div className="tooltip d-none d-md-block" role="tooltip"><div className="arrow"></div><div className="tooltip-inner"></div></div>'
              title="Widgets"
            >
              <a className="nav-link " onClick={()=>alert("Do something here...")}>
                <i className="fe fe-grid"></i>{" "}
                <span className="d-md-none">Widgets</span>
              </a>
            </li>
          </ul>

          <ul className="navbar-nav">
            <li
              className="nav-item"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title="Log Out"
            >
              <a className="nav-link " href="{{webRoot}}/widgets.html">
              <i className="bi bi-arrow-left-circle-fill"></i>
                <span className="d-md-none">LogOut</span>
              </a>
            </li>
          </ul>
        </Collapse>
      </div>
    </nav>
  );
}
