import React from "react";
import TopCards from "../components/home/TopCards";

const Home = () => {
  return (
    <div className="main-content">
      <div className="header">
        <div className="container-fluid">
          <div className="header-body">
            <div className="row align-items-end">
              <div className="col">
                <h6 className="header-pretitle">Overview</h6>

                <h1 className="header-title">Dashboard</h1>
              </div>
              <div className="col-auto">
                <a onClick={()=>alert("Do something...")} className="btn btn-primary lift">
                  {" "}
                  Create Report{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TopCards></TopCards>
    </div>
  );
};

export default Home;
