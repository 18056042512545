import React, {  } from "react";
// import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/airbnb.css";
import { PropTypes } from "prop-types";

const Card = (props) => {
  // const [date, setDate] = useState(new Date());

  return (
    <div className="col-12 col-lg-6 col-xl">
      <div>
      {/* <Flatpickr
        data-enable-time
        options={{mode:"range"}}
        value={date}
        onChange={(date) => {
          setDate(date);
        }}
      /> */}
      </div>
      <div className="card">
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col">
              <h6 className="text-uppercase text-muted mb-2">{props.title}</h6>

              <span className="h2 mb-0"> {props.text} </span>

              <span className="badge bg-success-soft mt-n1"> {props.tag} </span>
            </div>
            <div className="col-auto">
              <span className="h2 fe fe-dollar-sign text-muted mb-0"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  text : PropTypes.string.isRequired,
  title : PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired
}

export default Card;
