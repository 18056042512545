import React from "react";
import { useHistory } from "react-router";

const SignIn = () => {

    const history = useHistory();
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-md-5 col-xl-4 my-5">
          <h1 className="display-4 text-center mb-3">Sign in</h1>

          <p className="text-muted text-center mb-5">
            Welcome to the MiTel Services Dashboard.
          </p>

          <form>
            <div className="form-group">
              <label className="form-label">Username</label>

              <input
                type="text"
                className="form-control"
                placeholder="Username"
              />
            </div>

            <div className="form-group">
              <label className="form-label">Company</label>

              <input
                type="text"
                className="form-control"
                placeholder="Your Company Name"
              />
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col">
                  <label className="form-label">Password</label>
                </div>
                <div className="col-auto">
                  <a
                    href="password-reset-cover.html"
                    className="form-text small text-muted"
                  >
                    Forgot password?
                  </a>
                </div>
              </div>

              <div className="input-group input-group-merge">
                <input
                  className="form-control"
                  type="password"
                  placeholder="Enter your password"
                />

                <span className="input-group-text">
                  <i className="fe fe-eye"></i>
                </span>
              </div>
            </div>

            <button className="btn btn-lg w-100 btn-primary mb-3" onClick={()=> history.push("/")}>
              Sign in
            </button>

            {/* <div className="text-center">
              <small className="text-muted text-center">
                Don&apos;t have an account yet?{" "}
                <a href="sign-up.html">Sign up</a>.
              </small>
            </div> */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
